function App() {
  return (
    <main className="max-w-[932px] pb-8 pt-16 px-10">
      <h1 className="mb-6 md:text-6xl reckless text-4xl text-white">
        Welcome to your <br />
        React project
      </h1>
      <p className="text-lg">Here are some ideas how to get started</p>
      <ul className="gap-4 grid items-stretch md:gap-1 md:grid-cols-2 my-12">
        <li>
          <a
            className="block border border-[#514242] cursor-pointer flex group h-full hover:bg-[#5a47477e] items-center md:rounded-tl-2xl place-content-between px-6 py-10 rounded-md transition-colors"
            href="https://react.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <h2 className="font-bold mb-6 text-white text-xl">
                Official website
              </h2>
              <p>Learn more about React</p>
            </div>
            <svg
              width="24"
              height="24"
              fill="none"
              className="group-hover:opacity-100 opacity-0 transition-opacity"
            >
              <path
                fill="#fff"
                d="M13.736 5.634a.9.9 0 0 1 1.273 0l5.728 5.727a.896.896 0 0 1 .262.687l-.001.019a.898.898 0 0 1-.298.604l-5.69 5.69a.9.9 0 1 1-1.274-1.272l4.19-4.189H3.9a.9.9 0 1 1 0-1.8h14.03l-4.194-4.193a.9.9 0 0 1 0-1.273"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            className="block border border-[#514242] cursor-pointer flex group h-full hover:bg-[#5a47477e] items-center md:rounded-tr-2xl place-content-between px-6 py-10 rounded-md transition-colors"
            href="https://react.dev/reference/react"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <h2 className="font-bold mb-6 text-white text-xl">
                Documentation
              </h2>
              <p>Read the official documentation</p>
            </div>
            <svg
              width="24"
              height="24"
              fill="none"
              className="group-hover:opacity-100 opacity-0 transition-opacity"
            >
              <path
                fill="#fff"
                d="M13.736 5.634a.9.9 0 0 1 1.273 0l5.728 5.727a.896.896 0 0 1 .262.687l-.001.019a.898.898 0 0 1-.298.604l-5.69 5.69a.9.9 0 1 1-1.274-1.272l4.19-4.189H3.9a.9.9 0 1 1 0-1.8h14.03l-4.194-4.193a.9.9 0 0 1 0-1.273"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            className="block border border-[#514242] cursor-pointer flex group h-full hover:bg-[#5a47477e] items-center md:rounded-bl-2xl place-content-between px-6 py-10 rounded-md transition-colors"
            href="https://github.com/kinsta/hello-world-react"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <h2 className="font-bold mb-6 text-white text-xl">
                Kinsta starter
              </h2>
              <p>Download our starter repository for React</p>
            </div>
            <svg
              width="24"
              height="24"
              fill="none"
              className="group-hover:opacity-100 opacity-0 transition-opacity"
            >
              <path
                fill="#fff"
                d="M13.736 5.634a.9.9 0 0 1 1.273 0l5.728 5.727a.896.896 0 0 1 .262.687l-.001.019a.898.898 0 0 1-.298.604l-5.69 5.69a.9.9 0 1 1-1.274-1.272l4.19-4.189H3.9a.9.9 0 1 1 0-1.8h14.03l-4.194-4.193a.9.9 0 0 1 0-1.273"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            className="block border border-[#514242] flex group h-full hover:bg-[#5a47477e] items-center md:rounded-br-2xl place-content-between px-6 py-10 rounded-md transition-colors"
            href="https://github.com/kinsta"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <h2 className="font-bold mb-6 text-white text-xl">
                Kinsta GitHub
              </h2>
              <p>See our other resources and starter repositories</p>
            </div>
            <svg
              width="24"
              height="24"
              fill="none"
              className="group-hover:opacity-100 opacity-0 transition-opacity"
            >
              <path
                fill="#fff"
                d="M13.736 5.634a.9.9 0 0 1 1.273 0l5.728 5.727a.896.896 0 0 1 .262.687l-.001.019a.898.898 0 0 1-.298.604l-5.69 5.69a.9.9 0 1 1-1.274-1.272l4.19-4.189H3.9a.9.9 0 1 1 0-1.8h14.03l-4.194-4.193a.9.9 0 0 1 0-1.273"
              />
            </svg>
          </a>
        </li>
      </ul>
      <p>
        Join the{" "}
        <a
          href="https://community.kinsta.com"
          className="hover:underline text-[#EADDD5]"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kinsta Community forum
        </a>{" "}
        and connect with developers
      </p>
      <a
        className="block w-min"
        href="https://kinsta.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="100"
          height="40"
          fill="none"
          className="mt-10"
          viewBox="0 0 65 16"
        >
          <path
            fill="#fff"
            d="M.667 15.677H3.44v-5.034h4.94c.616 0 .924.3.924.9v4.134h2.771v-3.579c0-.923-.162-1.65-.485-2.182-.324-.53-.846-.92-1.571-1.166.663-.308 1.17-.826 1.524-1.559.355-.73.53-1.62.53-2.666v-.3H9.301v.53c0 1.154-.254 2.01-.762 2.563-.509.553-1.302.832-2.378.832H3.437V2.124H.667zM15.582 3.325c.509 0 .924-.154 1.247-.462.324-.308.485-.708.485-1.2 0-.494-.157-.893-.473-1.201C16.525.154 16.106 0 15.582 0c-.524 0-.924.154-1.247.462-.324.308-.486.707-.486 1.2s.162.893.486 1.2c.323.309.738.463 1.247.463m44.584 9.997h-4.387c-.616 0-.924-.3-.924-.9v-4.94c0-.6.308-.9.924-.9h4.387c.616 0 .923.3.923.9v4.94c0 .6-.307.9-.923.9M57.37 16c.846 0 1.577-.134 2.194-.403.616-.27 1.178-.696 1.686-1.283v1.363h2.61V4.225h-2.61v1.362c-.508-.584-1.07-1.011-1.686-1.282-.615-.269-1.346-.403-2.194-.403-1.74 0-3.095.55-4.063 1.65-.97 1.101-1.454 2.567-1.454 4.399 0 1.832.485 3.297 1.454 4.398C54.278 15.45 55.633 16 57.37 16m-8.312-.323h2.378v-2.448h-2.495c-.616 0-.923-.3-.923-.9V6.67h3.416V4.223h-3.416V1.752h-2.54v1.5c0 .648-.308.971-.924.971h-1.547v2.448h2.24v5.264c0 1.169.335 2.085 1.003 2.748.67.662 1.604.992 2.804.992m-16.508 0h5.449c3.124 0 4.687-1.117 4.687-3.349 0-1.015-.304-1.789-.913-2.32-.608-.53-1.611-.897-3.012-1.098l-2.31-.347c-.662-.107-1.093-.22-1.292-.335-.2-.115-.3-.32-.3-.612v-.208c0-.262.07-.45.209-.566.138-.115.376-.173.715-.173h6.14V4.219h-5.078c-1.57 0-2.756.289-3.557.866-.8.576-1.2 1.436-1.2 2.574 0 .986.312 1.74.935 2.263.624.524 1.643.892 3.06 1.108l2.24.324c.477.062.822.123 1.038.185.214.063.362.146.438.253.076.108.115.262.115.462v.23c0 .261-.074.45-.22.566-.146.114-.38.173-.703.173h-6.441v2.448zm-13.276 0h2.771V7.478c0-.6.308-.9.924-.9h4.04c.616 0 .924.3.924.9v8.197H30.7V7.87c0-1.2-.396-2.163-1.189-2.886-.793-.723-1.927-1.085-3.404-1.085-.893 0-1.706.146-2.436.438-.731.292-1.328.708-1.79 1.247V4.223h-2.609v11.452zm-5.078 0h2.77V4.225h-2.77v11.452z"
          />
        </svg>
      </a>
    </main>
  );
}

export default App;
